@tailwind base;
@tailwind components;
@tailwind utilities;

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	transition: all 0.3s;
	font-family: "Montserrat", sans-serif;
}

/* show menu */
.show-menu {
	right: 0;
}

/* Acrive Nav Menu */
.is-active {
	color: red;
}

.main__container {
	max-width: 1200px;
	margin-left: 1.5rem;
	margin-right: 1.5rem;
}

@media screen and (min-width: 768px) {
	.main__container {
		margin-left: 5rem;
		margin-right: 5rem;
	}
}

@media screen and (min-width: 1024px) {
	.main__container {
		margin-left: 1.5rem;
		margin-right: 1.5rem;
	}
}

@media screen and (min-width: 1200px) {
	.main__container {
		margin-left: auto;
		margin-right: auto;
	}
}

.swiper-button-next:after,
.swiper-button-prev:after {
	font-size: 1.3rem !important;
	font-weight: 600;
	color: #263734;
}
.swiper-button-prev,
.swiper-button-next {
	width: 55px !important;
	height: 55px !important;
	top: initial !important;
	bottom: 0rem !important;
	color: #263734 !important;
	padding: 0.8rem;
	border-radius: 50%;
	border: 2px solid #e91e63;
}
.swiper-button-prev {
	left: calc(50% - 3.8rem) !important;
}
.swiper-button-next {
	right: calc(50% - 3.8rem) !important;
}

.bg-trans {
	background-color: transparent !important;
	margin-top: 0 !;
}

.active-nav {
	position: relative;
}

.active-nav::before {
	content: "";
	width: 9px;
	height: 9px;
	border-radius: 50%;
	background-color: #00715d;
	position: absolute;
	bottom: -0.8rem;
	left: 50%;
	transform: translateX(-50%);
}

.block__content p,
.block__content ul,
.block__content h2,
.block__content h3,
.block__content h1,
.block__content h4 {
	margin-bottom: 1.25rem;
}

.block__content p {
	line-height: 1.6;
	/* font-size: 18px; */
}

.block__content h2 {
	font-size: 26px;
	font-weight: 600;
}

.block__content h3 {
	font-size: 22px;
	font-weight: 600;
}

.block__content ul {
	margin: 1rem;
	list-style-type: circle;
	line-height: 2;
	font-weight: 500;
}

@media screen and (max-width: 768px) {
	.active-nav {
		position: relative;
		background-color: white;
		padding: 14px 20px;
		color: #00715d;
	}

	.active-nav::before {
		content: "";
		width: 0;
		height: 0;
		/* border-radius: 50%; */
		background-color: unset;
		position: absolute;
		bottom: -0.8rem;
		left: 50%;
		transform: translateX(-50%);
	}
}
